.experience-body {
    scroll-margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 310px;
}
.qualification-container {
    width: 750px;
}
.qualifcation-category {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px 0;
}
.vertical-timeline-element-subtitle {
    color: var(--color-light);
    font-size: .8rem;
    text-align: center;
}
.vertical-timeline-element-title {
    font-size: 1.2rem;
    color: var(--color-white);
    text-align: center;
}
.experience, .education {
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--color-white);
    font-size: 1.5rem;
    transition: var(--transition);
}
.experience:hover, .education:hover {
    transform: scale(1.2);
}
.click {
    color: var(--color-primary);
}
.vertical-timeline-element-date {
    margin: 0 25px;
    color: var(--color-light);
}
/* ============================ MEDIA QUERIES (DOUBLE XL DEVICES) ============================ */
@media screen and (min-width: 2500px) {
    .experience-body {
        margin-bottom: 310px;
    }
}
/* ============================ MEDIA QUERIES (EXTRA LARGE DEVICES) ============================ */
@media screen and (min-width: 1200px) {
    .qualification-container {
        width: 900px;
    }
}
/* ============================ MEDIA QUERIES (LARGE DEVICES) ============================ */
@media screen and (max-width: 1170px) {
    .vertical-timeline-element-title, .vertical-timeline-element-subtitle {
        text-align: start;
    }
    .vertical-timeline-element-date {
        margin: 0;
    }
    .experience-body {
        height: auto;
        scroll-margin-top: 100px;
    }
    .qualification-container {
        width: 700px;
    }
}
/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 850px) {
    .experience-body {
        margin-bottom: 310px;
    }
    .qualification-container {
        width: 500px;
    }
}
/* ============================ MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 600px) {
   .experience {
    margin-right: 30px;
   }
   .education {
    margin-left: 30px;
   }
   .experience-body {
    margin-bottom: 150px;
    }
    .qualification-container {
        width: 300px;
    }
}
/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 400px) {
    .qualification-container {
        width: 250px;
    }
}
