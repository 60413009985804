footer {
    background: var(--color-primary);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}
footer a {
    color: #1f1f38;
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}
.footer-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    gap: 20px;
    margin-bottom: 2rem;
}
.footer-logo:hover {
    color: #1f1f38;
}
.footer-logo span{
    color: white;
}
.footer-img {
    width: 50px;
}

.footer-socials {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.footer-socials a:hover {
    color: #1f1f38;
    transform: scale(1.3);
}
.footer__copyright {
    margin-bottom: 4rem;
    color: #1f1f38;
}
.nav_style {
    position: relative;
}
.nav_style::after {
    content: "";
    position: absolute;
    background-color: white;
    width: 0;
    height: 2px;
    left: 0;
    bottom: -5px;
    transition: 0.3s;
}
.nav_style:hover::after {
    width: 100%;
}
.nav_style:hover {
    color: #1f1f38;
}

/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 600px) {
    .permalinks {
        display: flex;
        flex-direction: column;
    }
}