
.skill-box {
    scroll-margin-top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 90vh;
}
.skills-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    margin-top: 80px;
    gap: 30px;
}
.front-end, .back-end, .others {
    width: 400px;
    height: 580px;
    text-align: center;
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    box-shadow: var(--color-shadow);
    padding: 1rem;
    transition: var(--transition);
}
.front-end:hover, .back-end:hover, .others:hover {
    transform: scale(1.1);
}
.category-icon {
    font-size: 2rem;
    color: var(--color-primary);
}
.category {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
}

.skill {
    width: 50px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: var(--transition);
}
.prof-skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 10px;
}
.skills h5 {
    font-size: 0.95rem;
}
.prof-skills small {
    margin-top: -7px;
    font-size: 0.7rem;
    color: var(--color-light);
}
.skill:hover {
    transform: scale(1.2);
}

/* ============================ MEDIA QUERIES (LARGE DEVICES) ============================ */
@media screen and (max-width: 1800px){
    .skills-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    .skill-box {
        height: auto;
        margin-top: 0;
    }
    
}

/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px) {
    .skills-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }
    .skill-box {
        height: auto;
        width: 100%;
    }
}

/* ============================ MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 820px) {
    .skill-box {
        height: auto;
        width: 100%;

    }
    .skills-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
    }
}
/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 425px) {
    .front-end, .back-end, .others {
        width: 300px;
    }
    .front-end:hover, .back-end:hover, .others:hover {
        transform: scale(1);
    }
}
