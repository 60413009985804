.contact-body {
    margin-top: 150px;
    scroll-margin-top: 100px;
    height: 100vh;
}
.contact__container {
    margin-top: 100px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    place-content: center;
    gap: 12px;
}
.contact__options {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.contact__option {
    background-color: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid transparent;
    transition: var(--transition);
    box-shadow: var(--color-shadow);
    width: 280px;
    transition: var(--transition);
}
.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}
.contact__option:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    box-shadow: var(--color-shadow-variant);
}
.contact__option-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

/* ============================ FORM ================== */
form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
input, 
textarea {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    color: var(--color-white);
    box-shadow: var(--color-shadow);
    resize: none;
}
.msgIcon {
    margin-right: 14px;
}
.msg-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}
/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px) {
    .contact__container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-top: 80px;
    }
    .contact__options {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
    .contact__option {
        width: 100%;
    }
    .contact-body {
        height: auto;
        margin-bottom: 250px;
    }
    
}

/* ============================ MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 620px) {
    .contact__options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
    .contact__option {
        width: 100%;
    }
}
