.portfolio-body {
    height: auto;
    scroll-margin-top: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    margin-bottom: 150px;
    margin-top: 100px;
}
.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    margin-top: 60px;
    gap: 30px;
    row-gap: 70px;
}
.portfolio__item {
    position: relative;
    width: 400px;
    height: 570px;
    background: var(--color-bg-variant);
    box-shadow: var(--color-shadow-variant);
    padding: 1.3rem;
    border-radius: 1rem;
    transition: var(--transition);
}
.portfolio__item:hover {
    transform: scale(1.1);
}
.portfolio__item-img {
    margin-top: 20px;
    border-radius: 0.5rem;
    overflow: hidden;
}
.btn-design {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 50px;
}
article h3 {
    margin-top: 15px;
    font-size: 1.5rem;
}
article p {
    font-size: 14px;
    color: var(--color-light);
    margin-bottom: 15px;
}
.btn-portfolio {
    position: absolute;
    bottom: 20px;
    display: flex;
    gap: 15px;
}

/* ============================ MEDIA QUERIES (LARGE DEVICES) ============================ */
@media screen and (max-width: 1800px){
    .portfolio__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
    }
    .portfolio-body {
        height: auto;
        margin-top: 150px;
    }
}

/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        place-items: center;
        margin: 60px 25px 0;
    }
    .portfolio-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
/* ============================ MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 820px) {
    .portfolio__container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        margin: 60px 15px 0;
    }
}

/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 425px) {
    .portfolio__item {
        width: 300px;
        height: auto;
    }
    .portfolio__container {
        margin: 60px 0 0;
    }
    .portfolio__item:hover {
        transform: scale(1);
    }
    .port-h4 {
        margin-bottom: 80px;
    }
}
