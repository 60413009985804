.hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    position: relative;
}
header h5 {
    font-size: 1.5em;
}

.span-text {
    color: var(--color-primary);
    margin-left: 5px;
}

.typeWriter-container {
    text-align: start;
}

/* =============== CTA ============== */
.cta {
    margin-top: 2rem;
}

/* =============== HEADER SOCIALS ============== */
.header__socials {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 2rem;
}
.header__socials a {
    font-size: 25px;
}

/* =============== MY PICTURE ============== */
.img-container {
    width: 450px;
}
.me-container {
    position: relative;
    margin: 30px;
    cursor: pointer;
}
.me {
    overflow: hidden;
}
.me img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.2s ease-in-out;
}
.me-container::before {
    content: '';
    position: absolute;
    width: 30%;
    height: 30%;
    background: none;
    top: 0;
    left: 0;
    transform: translate(-5px, -5px);
    border-top: 5px solid var(--color-primary);
    border-left: 5px solid var(--color-primary);
    z-index: 10;
    transition: all 0.2s ease-in-out;
}
.me-container::after {
    content: '';
    position: absolute;
    width: 30%;
    height: 30%;
    background: none;
    bottom: 0;
    right: 0;
    transform: translate(5px, 5px);
    border-bottom: 5px solid var(--color-primary);
    border-right: 5px solid var(--color-primary);
    z-index: 10;
    transition: all 0.2s ease-in-out;
}
.me-container:hover::before, .me-container:hover:after {
    width: calc(100% + 20px);
    height: calc(100% + 20px);
}
.me-container:hover .me img{
    transform: scale(1.1);
}

/* ============================ MEDIA QUERIES (LARGE DEVICES) ============================ */
@media screen and (max-width: 1800px){
    .header__container {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin: 200px 0 150px 0;
        height: auto;
    }
    .typeWriter-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px) {
    .header__container {
        margin-top: 150px 0 120px 0;
    }
    .img-container {
        width: 400px;
    }
}

/* ============================ MEDIA QUERIES (SMALL DEVICES) ============================ */
@media screen and (max-width: 600px) {
    header h5 {
        font-size: 1rem;
    }
    header h1 {
        font-size: 1.8rem;
        font-weight: 600;
    }
    .header__container {
        margin-top: 150px 0 120px 0;
        gap: 30px;
    }
    .img-container {
        width: 300px;
    }
    .me-container {
        width: 300px;
        height: 100%;
    }
    .cta, .header__socials {
        margin-top: 1rem;
    }

}
/* ============================ MEDIA QUERIES (EXTRA SMALL DEVICES) ============================ */
@media screen and (max-width: 425px) {
    header h1 {
        font-size: 1.4rem;
        font-weight: 600;
    }
    .header__container {
        margin: 120px 0 100px 0;
        gap: 20px;
    }
    .me-container {
        width: 250px;
        height: auto;
    }
}
