.about__container {
    display: grid;
    margin-top: 100px;
    grid-template-columns: 35% 50%;
    gap: 15%;
    height: 80vh;
}
.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: var(--color-bg-img);
    box-shadow: var(--color-shadow);
    display: grid;
    place-items: center;
}

.about__me-text {
    margin-top: -30px!important;
}

.about__me-image {
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transition);
}

.about__me-image:hover {
    transform: rotate(0);
}

.about__cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    box-shadow: var(--color-shadow);
    transition: var(--transition);
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    box-shadow: var(--color-shadow-variant);
    cursor: default;
}

.about_icon {
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem 0 2.6rem;
    color: var(--color-light);
}

#about {
    margin-top: 150px;
    scroll-margin-top: 80px;
}

/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
        margin-top: 0;
        height: auto
    }
    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .about__content p {
        margin: 1rem 0 1.5rem;
    }
    #about {
        margin-bottom: 150px;
    }
}

/* ============================ MEDIA QUERIES (MEDIUM DEVICES) ============================ */
@media screen and (max-width: 600px) {
    .about__me {
        width: 65%;
        margin: 0 auto 3rem;
    }
    .about__cards{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
    .about__content {
        text-align: center;
    }
    .about__content p{
        margin: 1.5rem 0;
    }
    #about {
        margin-bottom: 120px;
    }
    .about__container {
        margin-top: 80px;
    }
}